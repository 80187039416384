<ng-container *transloco="let t">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">
      {{name}}

    </h5>
    <button type="button" class="btn-close text-reset" [attr.aria-label]="t('common.close')" (click)="close()"></button>
  </div>

  <div class="offcanvas-body">
    <ng-container *ngIf="CoverUrl as coverUrl">
      <div style="width: 160px" class="mx-auto mb-3">
        <app-image *ngIf="coverUrl" height="230px" width="160px" maxHeight="230px" objectFit="contain" [imageUrl]="coverUrl"></app-image>
      </div>
    </ng-container>

    <ng-container *ngIf="externalSeries; else localSeriesBody">
      <div *ngIf="(externalSeries.volumeCount || 0) > 0 || (externalSeries.chapterCount || 0) > 0" class="text-muted muted mb-2">
        {{t('series-preview-drawer.vols-and-chapters', {volCount: externalSeries.volumeCount, chpCount: externalSeries.chapterCount})}}
      </div>

      @if(isExternalSeries && externalSeries) {
        <div class="text-muted muted mb-2">
          {{t('series-preview-drawer.provided-by-label')}}
          <img class="ms-1" [ngSrc]="externalSeries.provider | providerImage" width="20" height="20" alt="">
        </div>
      }

      <app-read-more *ngIf="externalSeries.summary" [maxLength]="300" [text]="externalSeries.summary"></app-read-more>

      <div class="mt-3">
        <app-metadata-detail [tags]="externalSeries.genres" [libraryId]="0"  [heading]="t('series-preview-drawer.genres-label')">
          <ng-template #itemTemplate let-item>
            <app-tag-badge>
              {{item}}
            </app-tag-badge>
          </ng-template>
        </app-metadata-detail>
      </div>

      <div class="mt-3">
        <app-metadata-detail [tags]="externalSeries.tags" [libraryId]="0"  [heading]="t('series-preview-drawer.tags-label')">
          <ng-template #itemTemplate let-item>
            <app-tag-badge>
              {{item.name}}
            </app-tag-badge>
          </ng-template>
        </app-metadata-detail>
      </div>

      <div class="mt-3">
        <app-metadata-detail [tags]="externalSeries.staff" [libraryId]="0"  [heading]="t('series-preview-drawer.staff-label')">
          <ng-template #itemTemplate let-item>
            <div class="card mb-3" style="max-width: 180px;">
              <div class="row g-0">
                <div class="col-md-4">
                  <ng-container *ngIf="item.imageUrl && !item.imageUrl.endsWith('default.jpg'); else localPerson">
                    <app-image height="24px" width="24px" objectFit="contain" [imageUrl]="item.imageUrl" classes="person-img"></app-image>
                  </ng-container>
                  <ng-template #localPerson>
                    <i class="fa fa-user-circle align-self-center person-img" style="font-size: 28px;" aria-hidden="true"></i>
                  </ng-template>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h6 class="card-title">{{item.name}}</h6>
                    <p class="card-text" style="font-size: 14px"><small class="text-muted">{{item.role}}</small></p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </app-metadata-detail>
      </div>
    </ng-container>

    <ng-template #localSeriesBody>
      <ng-container *ngIf="localSeries">
        <div class="d-inline-block mb-2" style="width: 100%">
          <span class="text-muted muted">{{localSeries.publicationStatus | publicationStatus}}</span>
          <button class="btn btn-secondary btn-sm float-end me-3"
                  (click)="toggleWantToRead()"
                  ngbTooltip="{{wantToRead ? t('series-preview-drawer.remove-from-want-to-read') : t('series-preview-drawer.add-to-want-to-read')}}">
            <i class="{{wantToRead ? 'fa-solid' : 'fa-regular'}} fa-star" aria-hidden="true"></i>
          </button>
        </div>
        <app-read-more [maxLength]="300" [text]="localSeries.summary"></app-read-more>

        <div class="mt-3">
          <app-metadata-detail [tags]="localSeries.genres" [libraryId]="0"  [heading]="t('series-preview-drawer.genres-label')">
            <ng-template #itemTemplate let-item>
              <app-tag-badge>
                {{item.title}}
              </app-tag-badge>
            </ng-template>
          </app-metadata-detail>
        </div>

        <div class="mt-3">
          <app-metadata-detail [tags]="localSeries.tags" [libraryId]="0" [heading]="t('series-preview-drawer.tags-label')">
            <ng-template #itemTemplate let-item>
              <app-tag-badge>
                {{item.title}}
              </app-tag-badge>
            </ng-template>
          </app-metadata-detail>
        </div>

        <div class="mt-3">
          <app-metadata-detail [tags]="localStaff" [libraryId]="0" [heading]="t('series-preview-drawer.staff-label')">
            <ng-template #itemTemplate let-item>
              <div class="card mb-3" style="max-width: 180px;">
                <div class="row g-0">
                  <div class="col-md-4">
                    <i class="fa fa-user-circle align-self-center" style="font-size: 28px; margin-top: 24px; margin-left: 24px" aria-hidden="true"></i>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h6 class="card-title">{{item.name}}</h6>
                      <p class="card-text" style="font-size: 14px"><small class="text-muted">{{item.role}}</small></p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </app-metadata-detail>
        </div>

      </ng-container>
    </ng-template>

    <app-loading [loading]="isLoading"></app-loading>

    <a class="btn btn-primary col-12 mt-2" [href]="url" target="_blank" rel="noopener noreferrer">
      {{t('series-preview-drawer.view-series')}}
    </a>
  </div>
</ng-container>
